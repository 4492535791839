import {
  KBCard,
  KBCol,
  KBFlex,
  KBForm,
  KBRow,
  KBTypography,
} from '@/components/atoms'
import { useTranslation } from 'react-i18next'
const { Title, Text, Link } = KBTypography

import { AnimatePresence, motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { ArrowLeft } from 'lucide-react'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  LoginResponseDto,
  useAuthChooseSpaces,
  useAuthLogin,
  useRoleAssignmentFindRolesByUser,
} from '@/api'
import { AuthUserEntity } from '@/api/models/AuthUserEntity'
import { kbNotification } from '@/components/atoms/KBMessageGlobal'
import EmailForm from '@/pages/auth/components/EmailForm'
import LoginForm from '@/pages/auth/components/LoginForm'
import SelectSpace from '@/pages/auth/components/SelectSpace'
import { currentUserTokenAtom, logoutAtom } from '@/store'
import { setCurrentUserToken } from '@/utils/axios'

import { Form } from 'antd'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

const Panel = ({ id, children }: { id: string; children: React.ReactNode }) => {
  const { t } = useTranslation()
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  )
}

const Login: React.FC = () => {
  const { t } = useTranslation()
  const setUserToken = useSetAtom(currentUserTokenAtom)
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = KBForm.useForm<{ email: string; password: string }>()
  const agreeTerms = Form.useWatch('agree_terms', form)
  const [step, setStep] = useState<number>(1)
  const logout = useSetAtom(logoutAtom)

  const [authUsers, setAuthUsers] = useState<AuthUserEntity[]>([])
  const [authUser, setAuthUser] = useState<AuthUserEntity | undefined>(
    undefined
  )
  const [redirectUri, setRedirectUri] = useState<string | null>(null)
  const { mutate: loginByEmail } = useAuthLogin()
  const chooseSpace = useAuthChooseSpaces()

  useEffect(() => {
    logout()
    // Extract redirect_uri from URL
    const searchParams = new URLSearchParams(location.search)
    const redirect = searchParams.get('redirect_uri')
    if (redirect) {
      setRedirectUri(redirect)
    }
  }, [])

  const { data: userRoles, refetch: fetchRolesByUser } =
    useRoleAssignmentFindRolesByUser({
      query: {
        enabled: false,
      },
    })

  const onEnterEmail = () => {
    chooseSpace.mutate(
      {
        data: { email: form.getFieldValue('email') },
      },
      {
        onSuccess: (users: AuthUserEntity[]) => {
          const spaces = users.map((user) => user!)
          if (spaces.length == 0) {
            kbNotification.error(t('login.error'), {
              description: t('login.noSpace'),
            })
            return
          } else if (spaces.length == 1) {
            // 调用 onSelectAuthUser 进行验证
            onSelectAuthUser(spaces[0])
          } else {
            setAuthUsers(spaces)
            setStep(2)
          }
        },
      }
    )
  }

  const onSelectAuthUser = (user: AuthUserEntity) => {
    if (user.last_password_changed_at || user.last_sign_in_at) {
      setAuthUser(user)
      setStep(3)
    } else {
      navigate('/auth/first-login', {
        state: {
          user,
        },
      })
    }
  }

  const onLogin = () => {
    form.validateFields().then((values) => {
      console.log(values)
      loginByEmail(
        {
          data: {
            email: values.email,
            password: values.password,
            space_id: authUser?.space?.id!,
          },
        },
        {
          onSuccess: async (data: LoginResponseDto) => {
            if (data.token && data.refreshToken && data.sessionToken) {
              setUserToken(data.token)
              setCurrentUserToken(data.token)
              // forgot history
              // Reset navigation history to prevent going back to login page
              if (redirectUri) {
                // Redirect to the stored redirect_uri if it exists
                window.location.replace(redirectUri)
              } else {
                //  navigate('/user/dashboard', { replace: true })
                navigate('/user', { replace: true })
              }
            }
          },
        }
      )
    })
  }

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-w-full"
    >
      <KBFlex gap={24} vertical align="center">
        <WoxIconLogo />
        <Title level={2} className="tw-m-0">
          {step === 2 ? t('login_space_title') : t('login.login')}
        </Title>
      </KBFlex>

      <KBRow justify="center" className="tw-w-full">
        <KBCol span={20} className="tw-text-center">
          <KBFlex vertical justify="center" align="center" gap={32}>
            <KBCard className="tw-h-[24.6rem] tw-w-full tw-max-w-[430px] tw-overflow-y-auto ">
              <KBForm form={form} layout="vertical" onFinish={onLogin}>
                <AnimatePresence>
                  {step === 1 && (
                    <EmailForm form={form} onEnterEmail={onEnterEmail} />
                  )}
                  {step === 2 && (
                    <Panel id="panel2">
                      <SelectSpace
                        form={form}
                        authUsers={authUsers}
                        onSelectAuthUser={onSelectAuthUser}
                      ></SelectSpace>
                    </Panel>
                  )}
                  {step === 3 && (
                    <Panel id="panel3">
                      <LoginForm
                        form={form}
                        authUser={authUser!}
                        onLogin={onLogin}
                      ></LoginForm>
                    </Panel>
                  )}
                </AnimatePresence>
              </KBForm>
            </KBCard>

            {step === 1 ? null : ( // </Text> //   {tr('goRegisterText1')} <Link>{tr('goRegisterText2')}</Link> // <Text>
              <KBFlex
                gap={8}
                onClick={() => setStep(step - 1)}
                className="tw-cursor-pointer"
              >
                <motion.div
                  className="tw-pt-[3px]"
                  whileHover={{
                    x: -10,
                  }}
                >
                  <ArrowLeft size={18} />
                </motion.div>
                <span>{t('goBack')}</span>
              </KBFlex>
            )}
          </KBFlex>
        </KBCol>
      </KBRow>
    </KBFlex>
  )
}

export default Login
