import { ThemeConfig, theme } from 'antd'

interface CustomToken {
  // gray-50
  colorFillQuinary?: string
  colorAdminMenuLayout?: string
  colorAlertInfo?: string
  colorAlertError?: string
  colorAlertSuccess?: string
  colorAlertWarning?: string
  colorAlertInfoBg?: string
  colorAlertErrorBg?: string
  colorAlertSuccessBg?: string
  colorAlertWarningBg?: string
  colorStateIconYellow?: string
  colorStateIconBlue?: string
  colorStateIconGray?: string
  colorStateIconEmerald?: string
  colorUploadImageBg?: string
}
export interface WoxTheme extends ThemeConfig {
  token?: ThemeConfig['token'] & CustomToken // 将自定义 token 合并进来
}

export const lightTheme: WoxTheme = {
  token: {
    colorPrimaryBg: '#e8fffd',
    colorPrimaryBgHover: '#a9e6e4',
    colorPrimaryBorder: '#72cdcd',
    colorPrimaryBorderHover: '#45b0b3',
    colorPrimaryHover: '#20929a',
    colorPrimary: '#047481',
    colorInfo: '#047481',
    colorPrimaryActive: '#036574',
    colorPrimaryTextHover: '#025667',
    colorPrimaryText: '#01485a',
    colorPrimaryTextActive: '#003b4d',
    colorSuccessBg: '#dcfce7',
    colorSuccessBgHover: '#bbf7d0',
    colorSuccessBorder: '#86efac',
    colorSuccessBorderHover: '#4ade80',
    colorSuccessHover: '#22c55e',
    colorSuccess: '#16a34a',
    colorSuccessActive: '#15803d',
    colorSuccessTextHover: '#166534',
    colorSuccessText: '#14532d',
    colorSuccessTextActive: '#052e16',
    colorWarningBg: '#ffedd5',
    colorWarningBgHover: '#fed7aa',
    colorWarningBorder: '#fdba74',
    colorWarningBorderHover: '#fb923c',
    colorWarningHover: '#f97316',
    colorWarning: '#ea580c',
    colorWarningActive: '#c2410c',
    colorWarningTextHover: '#9a3412',
    colorWarningText: '#7c2d12',
    colorWarningTextActive: '#431407',
    colorErrorBg: '#fee2e2',
    colorErrorBgHover: '#fecaca',
    colorErrorBorder: '#fca5a5',
    colorErrorBorderHover: '#f87171',
    colorErrorHover: '#ef4444',
    colorError: '#dc2626',
    colorErrorActive: '#b91c1c',
    colorErrorTextHover: '#991b1b',
    colorErrorText: '#7f1d1d',
    colorErrorTextActive: '#450a0a',
    colorText: '#1f2937',
    colorTextSecondary: '#4b5563',
    colorTextTertiary: '#6b7280',
    colorTextQuaternary: '#9ca3af',
    colorBorder: '#d1d5db',
    colorBorderSecondary: '#e5e7eb',
    colorFill: '#d1d5db',
    colorFillSecondary: '#e5e7eb',
    colorFillTertiary: '#f3f4f6',
    colorFillQuaternary: '#f3f4f6',
    colorBgLayout: '#ffffff',
    colorBgSpotlight: '#030712',
    colorBgMask: '#030712',
    colorBgTextHover: '#F3F4F6',
    controlHeightLG: 36,
    lineWidthFocus: 2,
    lineHeightHeading1: 1.111111111111111,
    fontSizeHeading5: 18,
    fontSizeHeading1: 36,
    lineHeightHeading2: 1.2,
    lineHeightHeading5: 1.5555555555555556,
    lineHeight: 1.498571428571429,
    fontFamily:
      "'Inter Variable', Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,apple color emoji,segoe ui emoji,Segoe UI Symbol,noto color emoji",
    fontFamilyCode:
      "'Inter Variable', Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,apple color emoji,segoe ui emoji,Segoe UI Symbol,noto color emoji",
    borderRadiusLG: 6,
    boxShadowSecondary: '0px 2px 6px -1px rgba(0, 0, 0, 0.08)',
    colorFillQuinary: '#f9fafb',
    colorAdminMenuLayout: '#FBFBFB',
    colorAlertInfo: 'rgb(37,99,235)',
    colorAlertError: 'rgb(239,68,68)',
    colorAlertSuccess: 'rgb(5,150,105)',
    colorAlertWarning: 'rgb(234,88,12)',
    colorAlertInfoBg: 'rgb(219, 234, 254)',
    colorAlertErrorBg: 'rgb(254,226,226)',
    colorAlertSuccessBg: 'rgb(220,252,231)',
    colorAlertWarningBg: 'rgb(255,237,213)',
    colorStateIconYellow: 'rgba(251, 191, 36, 1)',
    colorStateIconBlue: 'rgba(96, 165, 250, 1)',
    colorStateIconGray: 'rgba(156, 163, 175, 1)',
    colorStateIconEmerald: 'rgba(16, 185, 129, 1)',
    colorUploadImageBg: 'rgba(255, 255, 255, 0.85)',
  },
  components: {
    Button: {
      paddingInline: 12,
      paddingInlineLG: 16,
      paddingInlineSM: 8,
      dangerShadow: '0 0 0 rgba(255, 38, 5, 0.06)',
      defaultShadow: '0 0 0 rgba(0, 0, 0, 0.02)',
      primaryShadow: '0 0 0 rgba(5, 145, 255, 0.1)',
      fontWeight: 500,
      onlyIconSizeSM: 16,
      onlyIconSize: 18,
      onlyIconSizeLG: 20,
      contentFontSizeLG: 14,
    },
    Dropdown: {
      paddingBlock: 7,
    },
    Select: {
      fontSizeLG: 14,
      multipleItemBg: 'rgb(243, 244, 246)',
      optionSelectedBg: 'rgb(229, 231, 235)',
    },
    Table: {
      cellPaddingInline: 8,
      headerBorderRadius: 6,
      rowHoverBg: 'rgb(249, 250, 251)',
    },
    DatePicker: {
      cellHeight: 32,
      cellWidth: 36,
      withoutTimeCellHeight: 50,
      fontSizeLG: 14,
      inputFontSizeLG: 14,
    },
    Badge: {
      statusSize: 7,
    },
    Segmented: {
      trackPadding: 3,
      trackBg: 'rgb(243, 244, 246)',
      fontSizeLG: 14,
    },
    Tooltip: {
      fontSize: 13,
    },
    Alert: {
      lineWidth: 0,
      defaultPadding: '10px 12px',
      withDescriptionPadding: '12px 12px',
      withDescriptionIconSize: 20,
      fontSizeLG: 14,
      colorInfo: 'rgb(37,99,235)',
      colorError: 'rgb(239,68,68)',
      colorSuccess: 'rgb(5,150,105)',
      colorWarning: 'rgb(234,88,12)',
      colorInfoBg: 'rgb(219, 234, 254)',
      colorErrorBg: 'rgb(254,226,226)',
      colorSuccessBg: 'rgb(220,252,231)',
      colorWarningBg: 'rgb(255,237,213)',
    },
    Message: {
      colorInfo: 'rgb(37, 99, 235)',
      contentPadding: '10px 12px',
    },
    Menu: {
      itemBorderRadius: 6,
      subMenuItemBg: 'rgba(0, 0, 0, 0)',
      itemHoverBg: 'rgb(243, 244, 246)',
      itemSelectedBg: 'rgb(229, 231, 235)',
      itemSelectedColor: 'rgb(31, 41, 55)',
      itemHeight: 36,
      iconMarginInlineEnd: 8,
      iconSize: 16,
      itemMarginInline: 12,
      itemActiveBg: 'rgb(229, 231, 235)',
      collapsedWidth: 64,
    },
    Cascader: {
      optionPadding: '7px 12px',
      fontSizeLG: 14,
    },
    Transfer: {
      itemHeight: 36,
    },
    Drawer: {
      colorBgMask: 'rgba(3, 7, 18, 0.45)',
    },
    Modal: {
      colorBgMask: 'rgba(3, 7, 18, 0.45)',
    },
    Pagination: {
      itemSize: 36,
    },
    Form: {
      fontSizeLG: 14,
    },
    Input: {
      inputFontSizeLG: 14,
    },
    InputNumber: {
      inputFontSizeLG: 14,
      fontSizeLG: 14,
    },
    Mentions: {
      inputFontSizeLG: 14,
    },
    Notification: {
      colorInfo: 'rgb(37, 99, 235)',
    },
    Tree: {
      titleHeight: 20,
      controlInteractiveSize: 16,
      marginXXS: 8,
      nodeSelectedBg: 'rgba(255, 255, 255, 0)',
      nodeHoverBg: 'rgba(255, 255, 255, 0)',
    },
    Calendar: {
      controlHeightSM: 40,
      paddingXXS: 1.2,
    },
    Collapse: {
      headerBg: 'rgb(249, 250, 251)',
      colorBorder: 'rgb(229, 231, 235)',
    },
    Tag: {
      colorPrimary: 'rgb(47, 84, 235)',
      'magenta-1': 'rgba(252, 231, 243, 1)',
      'magenta-3': 'rgba(251, 207, 232, 1)',
      'magenta-7': 'rgba(164, 23, 82, 1)',
      'red-1': 'rgba(254, 226, 226, 1)',
      'red-3': 'rgba(254, 202, 202, 1)',
      'red-7': 'rgba(153, 25, 25, 1)',
      'volcano-1': 'rgba(255, 241, 242, 1)',
      'volcano-3': 'rgba(253, 164, 175, 1)',
      'volcano-7': 'rgba(244, 63, 94, 1)',
      'orange-1': 'rgba(255, 237, 213, 1)',
      'orange-3': 'rgba(254, 215, 170, 1)',
      'orange-7': 'rgba(146, 49, 10, 1)',
      'gold-1': 'rgba(254, 249, 195, 1)',
      'gold-3': 'rgba(254, 240, 138, 1)',
      'gold-7': 'rgba(113, 63, 18, 1)',
      'lime-1': 'rgba(204, 251, 241, 1)',
      'lime-3': 'rgba(153, 246, 228, 1)',
      'lime-7': 'rgba(12, 93, 86, 1)',
      'green-1': 'rgba(220, 252, 231, 1)',
      'green-3': 'rgba(187, 247, 208, 1)',
      'green-7': 'rgba(17, 105, 50, 1)',
      'cyan-1': 'rgba(207, 250, 254, 1)',
      'cyan-3': 'rgba(165, 243, 252, 1)',
      'cyan-7': 'rgba(12, 92, 114, 1)',
      'blue-1': 'rgba(219, 234, 254, 1)',
      'blue-3': 'rgba(191, 219, 254, 1)',
      'blue-7': 'rgba(23, 61, 166, 1)',
      'geekblue-1': 'rgba(238, 242, 255, 1)',
      'geekblue-3': 'rgba(217, 224, 255, 1)',
      'geekblue-7': 'rgba(79, 70, 229, 1)',
      'purple-1': 'rgba(243, 232, 255, 1)',
      'purple-3': 'rgba(233, 213, 255, 1)',
      'purple-7': 'rgba(100, 27, 163, 1)',
      colorInfo: 'rgba(23, 61, 166, 1)',
      colorInfoBg: 'rgba(219, 234, 254, 1)',
      colorInfoBorder: 'rgba(191, 219, 254, 1)',
      defaultColor: 'rgb(55,65,81)',
      defaultBg: 'rgba(244, 244, 245, 1)',
      colorBorder: 'rgb(228,228,231)',
      lineWidth: 1,
    },
    Result: {
      marginXS: 4,
      titleFontSize: 16,
      paddingLG: 20,
    },
    Timeline: {
      itemPaddingBottom: 40,
    },
    Tabs: {
      horizontalMargin: '0 0 24px 0',
    },
    Upload: {
      lineWidth: 2,
      colorFillAlter: 'rgb(255,255,255)',
    },
    Skeleton: {
      gradientFromColor: 'rgb(0 0 0 / 4%)',
      gradientToColor: 'rgb(0 0 0 / 6%)',
    },
  },
  algorithm: theme.defaultAlgorithm,
}
