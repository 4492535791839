import { SpinProps } from 'antd'
import { useSpinDelay } from 'spin-delay'
export interface KBSpinProps extends SpinProps {}

export function KBSpin(props: KBSpinProps) {
  // const antIcon = <Loader2 className="tw-animate-spin" size={24} />
  const loading = useSpinDelay(props.spinning || true, {
    delay: props.delay || 1000,
  })
  if (!loading) return null
  return (
    <svg
      className="tw-flex tw-h-5 tw-w-5 tw-animate-spin tw-text-[var(--wox-color-text)]"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="tw-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="tw-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}
