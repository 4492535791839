// utils.ts

/**
 * 对名字进行处理，英文名取前两个单词的首字母，中文名取最后两个字符
 * @param name 传入的名字
 * @returns 处理后的名字
 */
export function sanitizeName(name: string): string {
  // 1. 去除非字母数字字符
  const sanitized = name.replace(/[^a-zA-Z0-9\u4e00-\u9fa5\s]/g, '') // 支持中文字符

  // 2. 判断名字是否包含中文字符
  const isChineseName = /[\u4e00-\u9fa5]/.test(sanitized)

  // 3. 处理英文名字：取前两个单词的首字母或取单词的前两个字母
  if (!isChineseName) {
    const words = sanitized.split(/\s+/) // 按空格分割成单词
    let initials = ''

    // 如果有两个或更多单词，取前两个单词的首字母
    if (words.length > 1) {
      initials = words
        .slice(0, 2) // 取前两个单词
        .map((word) => word.charAt(0).toUpperCase()) // 获取每个单词的首字母并大写
        .join('') // 连接起来
    } else {
      // 如果只有一个单词，取该单词的前两个字母
      initials = words[0].slice(0, 2).toUpperCase() // 取单词的前两个字母并大写
    }
    return initials
  }

  // 4. 处理中文名字：取后两个字符
  const initials = sanitized.slice(-2) // 取最后两个字符
  return initials
}
