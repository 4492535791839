import {
  IconName,
  KBButton,
  KBFlex,
  KBIcon,
  KBImage,
  KBTypography,
} from '@/components/atoms'
import PageContentLayout from '@/components/layouts/PageContentLayout'
import { CONTACT_EMAIL_ADDRESS } from '@/utils/constants'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
const { Text } = KBTypography
interface ItemType {
  itemTile: string
  itemDescription: string
  icon: IconName
}
interface KBUpgradeProps {
  title?: ReactNode
  subtitle?: string
  description?: string
  button?: ReactNode
  items?: ItemType[]
  type?: 'default' | 'visitor'
}

const KBUpgrade = (props: KBUpgradeProps) => {
  const { t } = useTranslation()
  const {
    title,
    subtitle,
    description,
    button,
    items,
    type = 'default',
  } = props

  return (
    <PageContentLayout title={title}>
      <div className="tw-m-[28px_auto_0px] tw-w-[1024px]">
        <KBFlex
          gap={0}
          className="tw-mb-5 tw-rounded-md tw-border tw-border-solid tw-border-[var(--wox-color-fill-quinary)] "
          style={{
            background:
              type === 'default'
                ? 'linear-gradient(0deg, #F9E8D9 0%, #F9E8D9 100%)'
                : 'linear-gradient(0deg, #E6EFFF 0%, #E6EFFF 100%)',
          }}
        >
          <div className="tw-box-border tw-w-[512px] tw-p-8">
            <Text className="tw-mb-3 tw-block tw-text-2xl tw-font-bold tw-text-[#1F2937]">
              {subtitle}
            </Text>
            <Text className="tw-mb-8  tw-block tw-text-[#1F2937]">
              {description}
            </Text>
            <KBFlex align="center" gap={12}>
              {type === 'default' ? (
                <>
                  <KBButton
                    icon={<KBIcon name="mail" />}
                    type="primary"
                    href={`mailto:${CONTACT_EMAIL_ADDRESS}`}
                  >
                    {t('common.contactUs')}
                  </KBButton>
                  <KBButton
                    type="text"
                    className="tw-mr-4 tw-text-[#1F2937]  hover:tw-bg-transparent"
                  >
                    <KBFlex
                      gap={8}
                      align="center"
                      onClick={() => {
                        window.open('https://woxday.com/', '_black')
                      }}
                    >
                      {t('common.knowMore')}
                      <KBIcon name="external-link" />
                    </KBFlex>
                  </KBButton>
                </>
              ) : (
                button
              )}
            </KBFlex>
          </div>
          <KBImage
            preview={false}
            src={
              type === 'default'
                ? '/subscribedFeature/background.svg'
                : '/subscribedFeature/visitorBackground.svg'
            }
          />
        </KBFlex>
        <KBFlex gap={20}>
          {items?.map((item, index) => {
            return (
              <div
                key={index}
                className="tw-box-border tw-w-[328px] tw-rounded-md tw-bg-[var(--wox-color-fill-quinary)] tw-p-6"
              >
                <KBIcon
                  name={item.icon}
                  className="tw-mb-4 tw-block tw-text-[var(--wox-color-text)]"
                  size={20}
                />
                <Text className="tw-mb-1 tw-block tw-text-base tw-font-medium">
                  {item.itemTile}
                </Text>
                <Text>{item.itemDescription}</Text>
              </div>
            )
          })}
        </KBFlex>

        {/* {button} */}
      </div>
    </PageContentLayout>
  )
}

export default KBUpgrade
