import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { currentUserTokenAtom, isAuthenticatedAtom } from '@/store'
import { setCurrentUserToken } from '@/utils/axios'

const RedirectToDashboard: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isAuthenticated] = useAtom(isAuthenticatedAtom)
  const searchParams = new URLSearchParams(location.search)
  const loginToken = searchParams.get('token')
  console.log('loginToken', loginToken)
  const setUserToken = useSetAtom(currentUserTokenAtom)

  useEffect(() => {
    if (isAuthenticated) {
      //     navigate('/user/dashboard')
      navigate('/user')
    } else if (loginToken) {
      setUserToken(loginToken)
      setCurrentUserToken(loginToken)
      // navigate('/user/dashboard', {
      //   replace: true,
      // })
      navigate('/user', {
        replace: true,
      })
    } else {
      console.log('redirect to login')
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  return null
}

export default RedirectToDashboard
