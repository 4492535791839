import { KBDatePicker } from '@/components/atoms/KBDatePicker'
import { KBIcon } from '@/components/atoms/KBIcon'
import { KBSelectProps } from '@/components/atoms/KBSelect'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBTimeSelectPicker } from '@/components/atoms/KBTimeSelectPicker'
import dayjs, { Dayjs, findClosestTimeWithStep, setToDate } from '@wox/dayjs'

export interface KBDateTimeRangeSelectProps
  extends Omit<KBSelectProps, 'value' | 'onChange' | 'mode'> {
  onChange?: (startTime?: Dayjs, endTime?: Dayjs) => void
  step?: number
  value?: [Dayjs | undefined, Dayjs | undefined]
  /**
   * 日期选择器禁用日期
   */
  disabledDate?: (date: Dayjs) => boolean
  /**
   * 开始时间选择器禁用时间
   */
  startDisabledTime?: (date: Dayjs) => boolean
  /**
   * 结束时间选择器禁用时间
   */
  endDisabledTime?: (date: Dayjs) => boolean
  /**
   * 隐藏日期选择器, 只选择时间范围
   */
  hideDatePicker?: boolean
  /**
   * 隐藏时间选择器
   */
  hideTimePicker?: boolean
  /**
   * 隐藏结束日期选择器，如果开始日期和结束日期是同一天
   */
  hideEndDateIfSameAsStartDate?: boolean

  /**
   * 开始时间是否使用当前时间
   */
  startUseCurrentTime?: boolean
  /**
   * 结束时间是否使用当前时间
   */
  endUseCurrentTime?: boolean
  /**
   * 严格使用value时间，不按照step计算--step可以被修改，有时候会需要展示修改前的数据，导致step推算后无法显示修改前的时间
   */
  strictlyUsingValue?: boolean
}

/**
 * 选择任意时间段，包括日 期选择和时间选择
 *
 * @param props
 * @returns
 */
export function KBDateTimeRangeSelect(props: KBDateTimeRangeSelectProps) {
  const {
    onChange,
    value,
    step = 30,
    className,
    allowClear = false,
    disabled,
    variant,
    disabledDate,
    startDisabledTime,
    endDisabledTime,
    hideDatePicker,
    hideTimePicker,
    hideEndDateIfSameAsStartDate = true,
    startUseCurrentTime,
    endUseCurrentTime,
    strictlyUsingValue = false,
    ...restProps
  } = props

  // 获取规整后的时间
  const getTimeWidthStepOrUsingValue = (time: Dayjs, timeStep: number) => {
    return strictlyUsingValue ? time : findClosestTimeWithStep(time, step)
  }

  const [startTime, setStartTime] = useState<Dayjs>(
    startUseCurrentTime ? dayjs() : getTimeWidthStepOrUsingValue(dayjs(), step)
  )
  const [endTime, setEndTime] = useState<Dayjs>(
    endUseCurrentTime
      ? dayjs()
      : getTimeWidthStepOrUsingValue(dayjs().add(1, 'hour'), step)
  )

  useEffect(() => {
    // console.log('>>>value', value?.[0]?.format())
    const start = !startUseCurrentTime
      ? getTimeWidthStepOrUsingValue(value?.[0] || dayjs(), step)
      : value?.[0] || dayjs()
    const end = !endUseCurrentTime
      ? getTimeWidthStepOrUsingValue(value?.[1] || dayjs(), step)
      : value?.[1] || dayjs()

    if (start.isSame(startTime) && end.isSame(endTime)) {
      // 减少render
      return
    }

    // 消除因为秒造成diff算错的影响
    setStartTime(start.set('second', 0).set('millisecond', 0))
    setEndTime(end)
  }, [value, step])

  const handleChange = (newStartTime: Dayjs, newEndTime: Dayjs) => {
    if (hideTimePicker) {
      onChange?.(newStartTime.startOf('day'), newEndTime.endOf('day'))
    } else {
      onChange?.(newStartTime, newEndTime)
    }
  }

  const handleStartDateChange = (date: Dayjs) => {
    if (startTime.isSame(date, 'day')) return
    let newStartTime: Dayjs = setToDate(startTime, date)
    let isInitStartTime = false
    if (startDisabledTime?.(newStartTime)) {
      newStartTime = dayjs()
      isInitStartTime = true
    }
    setStartTime(newStartTime)
    let newEndTime = endTime
    if (
      newStartTime.isSameOrAfter(endTime) ||
      startTime.isSame(endTime, 'day')
    ) {
      if (isInitStartTime) {
        // 开始时间过期了，重置开始和结束为1小时间隔
        newEndTime = findClosestTimeWithStep(newStartTime.add(1, 'hour'), step)
      } else {
        const duration = endTime.diff(startTime, 'minute')
        newEndTime = newStartTime.add(duration, 'minute')
      }
    }
    setEndTime(newEndTime)
    handleChange(newStartTime, newEndTime)
  }

  const handleEndDateChange = (date: Dayjs) => {
    if (endTime.isSame(date, 'day')) return
    let newEndTime: Dayjs = setToDate(endTime, date)
    if (newEndTime.isSameOrBefore(startTime, 'minute')) {
      newEndTime = startTime.add(step, 'minute')
    }
    if (endDisabledTime?.(newEndTime)) {
      newEndTime = dayjs().add(step, 'minute')
    }
    setEndTime(newEndTime)
    let newStartTime = startTime
    if (newEndTime.isSameOrBefore(startTime)) {
      newStartTime = newEndTime.subtract(step, 'minute')
      setStartTime(newStartTime)
    }
    handleChange(newStartTime, newEndTime)
  }

  const handleStartTimeChange = (date: Dayjs) => {
    let newStartTime: Dayjs = date
    if (startDisabledTime?.(newStartTime)) {
      newStartTime = dayjs()
    }
    setStartTime(newStartTime)
    let newEndTime = endTime
    if (newStartTime.isSameOrAfter(endTime)) {
      const duration = endTime.diff(
        findClosestTimeWithStep(startTime, step),
        'minute'
      )
      newEndTime = newStartTime.add(duration, 'minute')
    }
    setEndTime(newEndTime)
    handleChange(newStartTime, newEndTime)
  }

  const handleEndTimeChange = (date: Dayjs) => {
    let newEndTime: Dayjs = date
    // console.log('newEndTime', newEndTime.format())
    if (newEndTime.isSameOrBefore(startTime, 'minute')) {
      newEndTime = startTime.add(step, 'minute')
    }
    if (endDisabledTime?.(newEndTime)) {
      newEndTime = dayjs().add(step, 'minute')
    }
    setEndTime(newEndTime)
    let newStartTime = startTime
    if (newEndTime.isSameOrBefore(startTime)) {
      newStartTime = newEndTime.subtract(step, 'minute')
      setStartTime(newStartTime)
    }
    handleChange(newStartTime, newEndTime)
  }

  const disabledEndTime = useCallback(
    (date: Dayjs) => date.isBefore(startTime.add(step, 'minute'), 'minute'),
    [startTime, step]
  )

  const isSameDate = startTime?.isSame(endTime, 'day')
  // console.log('startTime', startUseCurrentTime, startTime.format())
  // console.log('endTime', endTime.format())

  return (
    <KBSpace wrap className="tw-flex-shrink-0">
      {!hideDatePicker && (
        <KBDatePicker
          className="tw-w-32"
          value={startTime}
          onChange={handleStartDateChange}
          disabledDate={disabledDate}
          allowClear={false}
          variant={variant}
        />
      )}
      {!hideTimePicker && (
        <KBTimeSelectPicker
          data-testid="start-time-picker"
          value={startTime}
          onChange={handleStartTimeChange}
          disabledTime={startDisabledTime}
          useCurrentTime={startUseCurrentTime || strictlyUsingValue}
          step={step}
          variant={variant}
          allowClear={false}
          {...restProps}
        />
      )}
      <KBIcon
        name="minus"
        className="tw-text-[var(--wox-color-text-quaternary)]"
      />
      {hideDatePicker ||
      (!hideTimePicker && hideEndDateIfSameAsStartDate && isSameDate) ? null : (
        <KBDatePicker
          className="tw-w-32"
          value={endTime}
          allowClear={false}
          onChange={handleEndDateChange}
          disabledDate={(date) => date.isBefore(startTime, 'day')}
          variant={variant}
        />
      )}
      {!hideTimePicker && (
        <KBTimeSelectPicker
          data-testid="end-time-picker"
          value={endTime}
          onChange={handleEndTimeChange}
          useCurrentTime={endUseCurrentTime || strictlyUsingValue}
          disabledTime={disabledEndTime}
          step={step}
          variant={variant}
          allowClear={false}
          includesEndOfDay
          {...restProps}
        />
      )}
    </KBSpace>
  )
}
