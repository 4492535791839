import NiceModal, { useModal } from '@ebay/nice-modal-react'
import dayjs, {
  Dayjs,
  findClosestTimeWithStep,
  formatDateTimeSeconds,
} from '@wox/dayjs'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import {
  CreateResourceReservationWithMultipleTimesDto,
  OnlineMeetingInfo,
  ReservationCustomFieldValue,
  ReservationShowEntity,
  ResourceEntity,
  ResourceReservationCreateMutationResponse,
  SimpleResourceReservationEntity,
  SimpleResourceWithPolicyEntity,
  UpdateResourceReservationDto,
  UpdateResourceReservationDtoAction,
  useResourceReservationAdminCreate,
  useResourceReservationAdminUpdate,
  useResourceReservationCreate,
  useResourceReservationUpdate,
} from '@/api'
import { KBDivider, KBForm, KBModal, KBSkeleton } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import AttendeesSelect from '@/components/molecules/KBResourceReservationForm/components/AttendeesSelect'
import CloudVideoSelect, {
  CloudVideoValueProps,
} from '@/components/molecules/KBResourceReservationForm/components/CloudVideoSelect'
import CustomFields from '@/components/molecules/KBResourceReservationForm/components/CustomFields'
import ReservationFormFooter from '@/components/molecules/KBResourceReservationForm/components/FormFooter'
import HostSelect from '@/components/molecules/KBResourceReservationForm/components/HostSelect'
import NoteInput from '@/components/molecules/KBResourceReservationForm/components/NoteInput'
import ReminderSelect from '@/components/molecules/KBResourceReservationForm/components/ReminderSelect'
import ResourceSelect from '@/components/molecules/KBResourceReservationForm/components/ResourceSelect'
import ServiceSelect, {
  ServiceValueItem,
} from '@/components/molecules/KBResourceReservationForm/components/ServiceSelect'
import SubjectInput from '@/components/molecules/KBResourceReservationForm/components/SubjectInput'
import TimeSlotSelect, {
  TimeSlotItem,
} from '@/components/molecules/KBResourceReservationForm/components/TimeSlotSelect'
import {
  fillRecurrenceInfoByRrule,
  recurrenceInfoToDto,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import { RecurrenceInfo } from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { ReservationTimeModeEnum } from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { currentUserDataAtom, localeAtom, locationAtom } from '@/store'
import { isValidEmail } from '@/utils/string'
import { isUserClient } from '@/utils/userEnvironment'
import { NamePath } from 'antd/es/form/interface'
import { debounce } from 'radash'

export interface KBResourceReservationFormProps {
  /**
   * 预定类型--周期性修改时传
   */
  recurringEditType?: UpdateResourceReservationDtoAction
  /**
   * 需要预定的资源
   */
  resourcesList: (ResourceEntity | SimpleResourceWithPolicyEntity)[]
  /**
   * 分店ID
   */
  locationId?: number
  /**
   * 开始时间，不传默认当前时间
   */
  startAt?: Dayjs
  /**
   * 结束时间，不传默认当前时间往后推1小时
   */
  endAt?: Dayjs
  /**
   * 时区
   */
  timeZone?: string
  /**
   * 时间模式
   */
  timeMode?: ReservationTimeModeEnum
  /**
   * 按日预定的日期，有则代表按日预定
   */
  multipleDates?: Dayjs[]
  /**
   * 周期性预定的数据
   */
  recurrenceInfo?: RecurrenceInfo
  /**
   * 预定信息--编辑或者展示时传
   */
  reservationInfo?: ReservationShowEntity
  /**
   * 预定成功时调用
   */
  onSuccess?: () => void
  /**
   * 层级
   */
  zIndex?: number
}

export interface FormValueProps {
  cloudVideoProviders?: CloudVideoValueProps
  host?: {
    hostId?: number
    isPrivate?: boolean
  }
  notes?: string
  reminderBefore?: number
  services?: ServiceValueItem[]
  subject: string
  timeData: TimeSlotItem
  userIds?: Array<string | number>
  // 自定义字段需要用到
  customFieldsValue?: {
    [key: string]: string[] | string | number | undefined
  }
}

/**
 * 如果外部传入的时间部分和规范，自动整理
 */
const formatPropsTime = (time: Dayjs) => {
  const minute = time.minute()
  if (minute < 30) {
    return time.startOf('hour')
  } else if (minute > 30) {
    return time.startOf('hour').add(0.5, 'hour')
  }
  return time
}

/**
 * 预定表单组件
 * @param locationId 分店ID
 * @param startAt 预定开始时间
 * @param endAt 预定结束时间
 * @param recurringEditType 周期性预定时，修改类型
 * @param onSuccess 成功回调
 */

function KBResourceReservationForm(props: KBResourceReservationFormProps) {
  const { t } = useTranslation()
  const {
    resourcesList,
    startAt,
    endAt,
    timeMode = ReservationTimeModeEnum.free,
    multipleDates,
    recurrenceInfo,
    locationId,
    recurringEditType,
    timeZone,
    reservationInfo,
    onSuccess,
    zIndex,
  } = props
  const currentLocationId = locationId || useCurrentLocationId()
  const [realStartAt, setRealStartAt] = useState(startAt)
  const [realEndAt, setRealEndAt] = useState(endAt)
  const [currentLocation] = useAtom(locationAtom)
  const [locale] = useAtom(localeAtom)
  useEffect(() => {
    if (!startAt || !endAt) {
      // 没有传入时间，使用默认值
      const step = currentLocation?.time_interval || 30
      const defaultStartAt = startAt || dayjs()
      const defaultEndAt = endAt || dayjs().add(1, 'hour')
      const _startAt = defaultStartAt
      const _endAt = findClosestTimeWithStep(defaultEndAt, step)
      setRealStartAt(_startAt)
      setRealEndAt(_endAt)
    }
  }, [startAt, endAt, currentLocation])

  // 初始化状态，用于异步加载
  const [initialed, setInitialed] = useState(false)
  const [errorsMessageByBackEnd, setErrorsMessageByBackEnd] =
    useState<Record<string, string>[]>()

  const createApi = isUserClient()
    ? useResourceReservationCreate
    : useResourceReservationAdminCreate

  const editApi = isUserClient()
    ? useResourceReservationUpdate
    : useResourceReservationAdminUpdate

  const { mutate: createResourceReservation, isPending: creating } = createApi({
    mutation: {
      onSuccess: (data: SimpleResourceReservationEntity[]) => {
        if (data.length >= 1) {
          const reservation = data[0] as SimpleResourceReservationEntity
          if (
            reservation.status === 'reserved' ||
            reservation.status === 'upcoming' ||
            reservation.status === 'starting_soon' ||
            reservation.status === 'ongoing' ||
            reservation.status === 'ending_soon'
          ) {
            kbMessage.success(t('reservation.reservationSuccess'))
          } else if (reservation.status === 'approving') {
            NiceModal.show('KBApprovalModal', {
              start_at: reservation?.start_at,
              end_at: reservation?.end_at,
              resourceId: reservation.resource_id,
            })
          }

          modal.resolve(reservation)
          modal.hide()
          onSuccess?.()
        }
      },
      onError(e) {
        console.log('error---', e)
      },
    },
  })

  const { mutate: updateResourceReservation, isPending: updating } = editApi({
    mutation: {
      onSuccess: () => {
        kbMessage.success(t('common.updateSuccess'))
        modal.resolve()
        modal.hide()
        onSuccess?.()
      },
      onError(e) {
        console.log('error---', e)
      },
    },
  })

  const validateCallbackFunctions = {
    onSuccess() {
      setErrorsMessageByBackEnd([])
    },
    onError(e: ResourceReservationCreateMutationResponse) {
      const errors = e?.response?.data?.errors
      setErrorsMessageByBackEnd(errors)
      if (Array.isArray(errors) && errors.length) {
        console.log('后端烦校验错误', errors)
        setErrorsMessageByBackEnd(errors)
      } else {
        setErrorsMessageByBackEnd([])
      }
    },
  }

  const { mutate: validateCreateData } = createApi({
    mutation: validateCallbackFunctions,
    invalidationOnSuccess: false,
  })

  const { mutate: validateUpdateData } = editApi({
    mutation: validateCallbackFunctions,
    invalidationOnSuccess: false,
  })

  const modal = useModal()
  const [form] = KBForm.useForm<FormValueProps>()
  const [currentUser] = useAtom(currentUserDataAtom)
  const currentUserId = currentUser!.id

  /**
   * 表单值变化时触发
   */
  const onFormFieldsChange = (
    fields: FormValueProps,
    allValues: FormValueProps
  ) => {
    // 后端校验
    validateByBackEnd()
  }

  // 获取时区计算后的时间，比如上海时区的10点转成伦敦的10点
  const getTimeZoneCurrentTime = (values: FormValueProps) => {
    const localeTimeZone = dayjs.tz.guess()
    const { startAt, endAt, timeMode, timeZone } = values.timeData
    if (timeMode === ReservationTimeModeEnum.free) {
      // 预定非本地时区的需要转换时间
      return {
        start_at: dayjs
          .tz(
            formatDateTimeSeconds(startAt!, {
              // 用'MM/DD/YYYY'格式转出来会有问题，不能取分店配置的转换规则
              dateFormat: 'YYYY-MM-DD',
              timeFormat: 24,
            }),
            timeZone
          )
          .format(),
        end_at: dayjs
          .tz(
            formatDateTimeSeconds(endAt!, {
              dateFormat: 'YYYY-MM-DD',
              timeFormat: 24,
            }),
            timeZone
          )
          .format(),
      }
    }
    return {
      start_at: values.timeData.startAt!.format(),
      end_at: values.timeData.endAt!.format(),
    }
  }

  /**
   * 整合数据结构
   */
  const formatData = (
    values: FormValueProps,
    dryrun: boolean = false
  ):
    | CreateResourceReservationWithMultipleTimesDto
    | UpdateResourceReservationDto => {
    const repeatInfo = values.timeData?.recurrenceInfo
    const timeZone = values.timeData?.timeZone

    const repeatInfoData = recurrenceInfoToDto(repeatInfo, timeZone)

    // 外部参会人 -- 手动输入
    const inputExternalAttendees = values.userIds?.filter(
      (i) => typeof i === 'string'
    )

    // 外部参会人 -- 选择id
    const selectExternalAttendees = (
      values.userIds?.filter((i) => typeof i === 'number' && i < 0) || []
    ).map((i) => Math.abs(i as number))

    // 内部参会人
    const internalAttendees = values.userIds?.filter(
      (i) => typeof i === 'number' && i > 0
    )

    // 自定义字段数据组装
    const customFields: ReservationCustomFieldValue[] = []
    if (!dryrun) {
      const { customFieldsValue } = values
      for (const key in customFieldsValue) {
        let value: string[] | undefined
        // 现在所有的值都是数组
        if (Array.isArray(customFieldsValue[key])) {
          value = customFieldsValue[key] as string[]
        } else {
          value = customFieldsValue[key] ? [String(customFieldsValue[key])] : []
        }
        if (key.includes('__customField__') && value) {
          const resourceId = key.split('__customField__')[0]
          const fieldId = key.split('__customField__')[1]
          customFields.push({
            custom_field: {
              label: 'label',
              value: value,
              custom_field_id: Number(fieldId),
            },
            resource_id: Number(resourceId),
          })
        }
      }
    }

    let online_meeting_info: OnlineMeetingInfo | undefined
    if (values.cloudVideoProviders?.thirdPartyMeeting) {
      const external_calendar = (
        (resourcesList || []).find(
          (item) => item.external_calendar_id
        ) as ResourceEntity
      )?.external_calendar
      const meetingProvider =
        external_calendar?.calendar_type === 'Outlook'
          ? 'teams'
          : 'googleHangout'
      online_meeting_info = {
        join_url: reservationInfo?.online_meeting_info?.join_url,
        meeting_provider:
          reservationInfo?.online_meeting_info?.meeting_provider ||
          meetingProvider,
      }
    } else if (values.cloudVideoProviders?.joinUrl) {
      online_meeting_info = {
        join_url: values.cloudVideoProviders.joinUrl,
        meeting_provider: 'unknown',
      }
    } else {
      online_meeting_info = undefined
    }

    const commonData = {
      dryrun,
      action: recurringEditType,
      host_id: values.host?.hostId,
      is_private: values.host?.isPrivate,
      online_meeting_info: online_meeting_info,
      notes: values.notes,
      reminder_before: values.reminderBefore,
      subject: values.subject,
      user_ids: internalAttendees?.length
        ? (internalAttendees as number[])
        : undefined,
      external_attendees: inputExternalAttendees?.length
        ? (inputExternalAttendees as string[])
        : undefined,
      user_book_ids: selectExternalAttendees?.length
        ? (selectExternalAttendees as number[])
        : undefined,
      location_id: currentLocationId!,
      time_mode: values.timeData.timeMode,
      // tzid: values.timeData.timeZone,
      services: (values?.services || []).map((i) => {
        const serviceItems = i.serviceItems.map((j) => {
          return {
            quantity: j.quantity,
            service_item_id: j.serviceItemId,
          }
        })
        return {
          resource_id: i.resourceId,
          service_type_id: i.serviceTypeId,
          notes: i.notes,
          service_items: serviceItems.length > 0 ? serviceItems : undefined,
        }
      }),
      recurring_event: repeatInfoData,
      custom_fields: customFields,
    }

    if (reservationInfo) {
      return {
        ...commonData,
        resource_id: reservationInfo.resource_id as number,
        ...getTimeZoneCurrentTime(values),
      }
    } else {
      return {
        ...commonData,
        resource_ids: resourcesList.map((i) => i.id),
        reservation_times: values.timeData.multipleDates
          ? values.timeData.multipleDates.map((i) => {
              return {
                start_at: i.startOf('day').format(),
                end_at: i.endOf('day').format(),
              }
            })
          : [getTimeZoneCurrentTime(values)],
      }
    }
  }

  /**
   * 回填数据
   */
  const fillFormData = (reservationInfo: ReservationShowEntity) => {
    const customFieldsValue: FormValueProps['customFieldsValue'] = {}
    if (reservationInfo.custom_fields?.length) {
      reservationInfo.custom_fields.forEach((i) => {
        if (!i.custom_field.value) {
          return
        }
        const customField =
          reservationInfo.resource?.reservation_form_rule?.custom_fields?.find(
            (customField) => customField.id === i.custom_field.custom_field_id
          )

        // radio单独处理
        customFieldsValue[
          `${i.resource_id}__customField__${i.custom_field.custom_field_id}`
        ] =
          customField?.type === 'Radio' && Array.isArray(i.custom_field.value)
            ? i.custom_field.value?.[0]
            : i.custom_field.value
      })
    }

    form.setFieldsValue({
      services: reservationInfo?.service_reservations?.map((service) => {
        return {
          serviceTypeId: service.service_type_id,
          notes: service.notes,
          serviceItems: service.service_item_reservations?.map((item) => {
            return {
              serviceItemId: item.service_item_id,
              quantity: item.quantity,
              total: item.quantity * Number(item.price),
            }
          }),
        }
      }),
      timeData: {
        startAt: dayjs(reservationInfo.start_at),
        endAt:
          reservationInfo.time_mode === 'fullDay'
            ? dayjs(reservationInfo.end_at).subtract(1, 'second')
            : dayjs(reservationInfo.end_at),
        timeMode: reservationInfo.time_mode,
        timeZone: timeZone || dayjs.tz.guess(),
        // reservationInfo.tzid ||
        // Intl.DateTimeFormat().resolvedOptions().timeZone,
        recurrenceInfo: fillRecurrenceInfoByRrule(
          reservationInfo.recurrence_rrule,
          reservationInfo.start_at
        ),
      },
      host: {
        hostId: reservationInfo.host_id,
        isPrivate: reservationInfo.is_private,
      },
      userIds: reservationInfo?.attendees
        ?.filter((i) => i.role !== 'host')
        ?.map((i) => {
          if (i.is_external) {
            return 0 - (i.user_book_id as number)
          } else {
            return i.user_id as number
          }
        }),
      subject: reservationInfo?.subject,
      reminderBefore: reservationInfo.reminder_before || 0,
      cloudVideoProviders: {
        joinUrl: reservationInfo?.online_meeting_info?.join_url,
        meetingProvider: reservationInfo?.online_meeting_info?.meeting_provider,
        thirdPartyMeeting: [
          'teams',
          'skypeForBusiness',
          'googleHangout',
        ].includes(reservationInfo.online_meeting_info?.meeting_provider || ''),
      },
      notes: reservationInfo?.notes,
      customFieldsValue,
    })

    setTimeout(() => {
      setInitialed(true)
    }, 0)
  }

  /**
   * 预定时间以及各种时间冲突的校验--调用后端接口校验
   */
  const validateByBackEnd = useCallback(
    debounce({ delay: 800 }, () => {
      const values = form.getFieldsValue(true)
      const data = formatData(values, true)

      // 校验外部参会人邮箱格式
      if (data.external_attendees?.some((i) => !isValidEmail(i))) {
        // 校验外部参会人的邮箱是否合法
        const errors = errorsMessageByBackEnd || []
        setErrorsMessageByBackEnd([
          ...errors,
          {
            key: 'attendee',
            value: t('common.enterValidEmail'),
          },
        ])
        return
      }

      if (
        !reservationInfo &&
        (data as CreateResourceReservationWithMultipleTimesDto)
          .reservation_times?.length === 0
      ) {
        const errors = errorsMessageByBackEnd || []
        setErrorsMessageByBackEnd([
          ...errors,
          {
            key: 'time',
            value: t('common.validateReserveTime'),
          },
        ])
        return
      }

      if (reservationInfo) {
        return validateUpdateData({
          id: reservationInfo.id,
          data: data as UpdateResourceReservationDto,
        })
      } else {
        return validateCreateData({
          data: data as CreateResourceReservationWithMultipleTimesDto,
        })
      }
    }),
    [reservationInfo]
  )

  const getFieldValidateMessages = (keys: string[]) => {
    const fieldErrors = errorsMessageByBackEnd?.filter((i) =>
      keys.includes(i.key)
    )

    if (fieldErrors?.length) {
      return fieldErrors.map((i) => i.value).join(';')
    }
  }

  /**
   * 跳转到form中对应的name字段的位置
   */
  const scrollToFormField = (namePath: NamePath) => {
    form.scrollToField(namePath, {
      behavior: 'smooth',
      block: 'center',
    })
  }

  function onOk(agreeErrored?: boolean, isAgree?: boolean) {
    // 后端校验错误
    if (errorsMessageByBackEnd?.length) {
      // errorsMessageByBackEnd里面的key和前端form的name不一致，这里对应转换
      const keyName = {
        service: 'services',
        time: 'timeData',
        host: 'host',
        private: 'host',
        attendee: 'userIds',
        subject: 'subject',
        remindBefore: 'reminderBefore',
        onlineMeeting: ['cloudVideoProviders', 'joinUrl'],
        note: 'notes',
      }
      scrollToFormField(
        keyName[errorsMessageByBackEnd?.[0].key as keyof typeof keyName]
      )
      return
    }

    form
      .validateFields()
      .then((values) => {
        // 未勾选协议，返回
        if (agreeErrored) {
          return
        }
        const data = formatData(values)
        if (isAgree) {
          data.is_check_protocol = isAgree
        }
        if (reservationInfo?.id) {
          updateResourceReservation({
            id: reservationInfo.id,
            data: data as UpdateResourceReservationDto,
          })
        } else {
          createResourceReservation({
            data: data as CreateResourceReservationWithMultipleTimesDto,
          })
        }
      })
      .catch((err) => {
        console.log('表单校验错误', err)
        const { errorFields } = err
        if (errorFields?.length) {
          scrollToFormField(errorFields[0].name)
        }
      })
  }

  const isSubmitting = useMemo(() => {
    return creating || updating
  }, [creating, updating])

  /**
   * 回填表单数据
   */
  useEffect(() => {
    if (reservationInfo) {
      fillFormData(reservationInfo)
    } else {
      setInitialed(true)
    }
  }, [reservationInfo])

  console.log('rerender 预定表单')

  return (
    <KBModal
      title={t('reservation.reservedResource')}
      width={714}
      zIndex={zIndex}
      modal={modal}
      footer={
        <ReservationFormFooter
          resourcesList={resourcesList}
          reservationInfo={reservationInfo}
          isSubmitting={isSubmitting}
          onOk={onOk}
          onCancel={modal.hide}
        />
      }
    >
      <KBSkeleton loading={!initialed}>
        <KBForm
          form={form}
          labelCol={{ flex: '40px' }}
          labelAlign="left"
          colon={false}
          initialValues={{
            timeData: {
              startAt: realStartAt, // 已经归整了
              endAt: realEndAt, // 已经归整了
              timeMode: timeMode,
              timeZone: timeZone || dayjs.tz.guess(),
              multipleDates: multipleDates,
              recurrenceInfo,
            },
            host: {
              hostId: currentUserId,
              isPrivate: false,
            },
            reminderBefore: 0,
          }}
          onValuesChange={onFormFieldsChange}
        >
          {/* 选择资源 */}
          <ResourceSelect
            errorsMessages={getFieldValidateMessages(['resource'])}
            selectedResourceList={resourcesList}
          />

          {/* 选择服务 */}
          <ServiceSelect
            errorsMessages={getFieldValidateMessages(['service'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {/* 选择时间模式 */}
          <TimeSlotSelect
            step={currentLocation?.time_interval}
            errorsMessages={getFieldValidateMessages(['time'])}
            resourcesList={resourcesList}
            recurringEditType={recurringEditType}
          />

          {/* 主持人和私密预定 */}
          <HostSelect
            errorsMessages={getFieldValidateMessages(['host', 'private'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {(resourcesList || []).some((i) => {
            const rules = i.reservation_reserve_rule?.rules
            const isShareAble = i.resource_type.usage_mode === 'shareable'
            const showAttendees =
              (rules?.external_attendees || rules?.internal_attendees) &&
              isShareAble
            const showReminder = rules?.reminder
            const showSubject = rules?.subject
            return showAttendees || showReminder || showSubject
          }) && <KBDivider className="tw-my-8" />}

          {/* 参会人 */}
          <AttendeesSelect
            errorsMessages={getFieldValidateMessages(['attendee'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {/* 主题 */}
          <SubjectInput
            errorsMessages={getFieldValidateMessages(['subject'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {/* 预定提醒 */}
          <ReminderSelect
            errorsMessages={getFieldValidateMessages(['remindBefore'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {/* 云会议 */}
          <CloudVideoSelect
            errorsMessages={getFieldValidateMessages(['onlineMeeting'])}
            resourcesList={resourcesList}
          />

          {/* 备注 */}
          <NoteInput
            errorsMessages={getFieldValidateMessages(['note'])}
            resourcesList={resourcesList}
            reservationInfo={reservationInfo}
          />

          {/* 自定义字段 */}
          <CustomFields resourcesList={resourcesList} />
        </KBForm>
      </KBSkeleton>
    </KBModal>
  )
}

export const KBResourceReservationFormModal = NiceModal.create(
  KBResourceReservationForm
)
