import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { LoadingType } from '@/components/atoms'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import {
  currentRefreshTokenAtom,
  currentSessionTokenAtom,
  currentUserTokenAtom,
} from '@/store'
import { setCurrentUserToken } from '@/utils/axios'

const AuthSuccess = () => {
  const location = useLocation()
  const setUserToken = useSetAtom(currentUserTokenAtom)
  const setRefreshToken = useSetAtom(currentRefreshTokenAtom)
  const setSessionToken = useSetAtom(currentSessionTokenAtom)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const refreshToken = params.get('refreshToken')
    const sessionToken = params.get('sessionToken')

    if (token && refreshToken && sessionToken) {
      // enable jwt
      setCurrentUserToken(token)
      setUserToken(token)
      // fetch user using token
      // fetchMe(
      //   {},
      //   {
      //     onSuccess: (data: LoginResponseDto) => {
      //       setCurrentUserToken(data.token)
      //       setUserToken(data.token)
      //       setRefreshToken(data.refreshToken)
      //       setSessionToken(data.sessionToken)
      //       // Redirect to the main app or dashboard
      //       window.location.href = '/user/dashboard'
      //     },
      //     onError: () => {
      //       window.location.href = '/auth/failure?error=invalid_token'
      //     },
      //   }
      // )
      // window.location.href = '/user/dashboard'
      window.location.href = '/user'
    } else {
      // Handle error
      window.location.href = '/auth/failure?error=missing_token'
    }
  }, [location])

  return (
    <KBSuspenseLoading loadingType={LoadingType.Progress} fullScreen={true} />
  )
}

export default AuthSuccess
