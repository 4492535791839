import React from 'react'

import { ReservationShowEntity } from '@/api'
import { KBForm, KBIcon, KBTooltip } from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { KBUserSelect } from '@/components/organisms'
import { t } from 'i18next'

interface AttendeesSelectProps {
  value?: number[]
  resourcesList: KBResourceReservationFormProps['resourcesList']
  reservationInfo?: ReservationShowEntity
  errorsMessages?: string
  onChange?: (value: (number | string)[]) => void
}
/**
 * 选择参会人组件
 */
const AttendeesSelect: React.FC<AttendeesSelectProps> = (props) => {
  const { value, onChange, resourcesList, reservationInfo, errorsMessages } =
    props

  const showAttendees = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      const isShareAble = i.resource_type.usage_mode === 'shareable'
      return (
        (rules?.external_attendees || rules?.internal_attendees) && isShareAble
      )
    })
  }, [resourcesList])

  const includes = useMemo(() => {
    const result: ('internal' | 'external')[] = []
    if (
      (resourcesList || []).some(
        (i) => i.reservation_reserve_rule?.rules?.internal_attendees
      )
    ) {
      result.push('internal')
    }
    if (
      (resourcesList || []).some(
        (i) => i.reservation_reserve_rule?.rules?.external_attendees
      )
    ) {
      result.push('external')
    }
    return result
  }, [resourcesList])

  return showAttendees ? (
    <KBForm.Item
      name="userIds"
      label={
        <KBTooltip title={t('common.attendees')}>
          <KBIcon name="users" size={24} />
        </KBTooltip>
      }
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBUserSelect
        mode="tags"
        includes={includes}
        className="tw-w-full"
        value={value}
        onChange={onChange}
        placeholder={t('common.attendsSelectPlaceHolder')}
        // TODO暂时先取消缓存，后续应该改成创建预定后，如果有添加了外部参会人才刷新缓存
        userBookStaleTime={1}
      />
    </KBForm.Item>
  ) : null
}

export default React.memo(AttendeesSelect)
