import { AuthUserEntity } from '@/api'
import { KBFlex, KBImage, KBSpace, KBTypography } from '@/components/atoms'
import { generateFromString } from 'generate-avatar'
const { Text } = KBTypography

import { ChevronRight } from 'lucide-react'
import React from 'react'

interface SpaceItemProps {
  user: AuthUserEntity
  showArrow?: boolean
  onSelect?: () => void
  isHovering?: boolean
  width?: number
}

const SpaceItem: React.FC<SpaceItemProps> = ({
  user,
  showArrow = true,
  onSelect,
  isHovering,
  width = 48,
}) => {
  const spaceName = user?.space?.name
  const space = user?.space
  const imageUrl =
    space?.white_logo ||
    (spaceName && `data:image/svg+xml;utf8,${generateFromString(spaceName)}`)
  const backgroundStyle = {
    background: `url(${imageUrl}) center / contain no-repeat `,
    width: `${width}px`, // 设置背景宽度
    height: `${width}px`, // 设置背景高度（可以根据需要调整）
  }

  return (
    <KBFlex className="tw-flex" onClick={onSelect} align="center">
      {/* <KBImage
        preview={false}
        width={width}
        src={
          space?.white_logo ||
          (spaceName &&
            `data:image/svg+xml;utf8,${generateFromString(spaceName)}`)
        }
      /> */}

      {!space?.white_logo ? (
        <KBImage
          preview={false}
          width={width}
          src={
            spaceName &&
            `data:image/svg+xml;utf8,${generateFromString(spaceName)}`
          }
        />
      ) : (
        <div style={backgroundStyle}></div>
      )}

      <KBSpace
        direction="vertical"
        size={3}
        className="tw-ml-3 tw-flex tw-flex-1 tw-text-left"
      >
        <Text strong className="tw-text-left">
          {spaceName}
        </Text>
        <Text type="secondary" ellipsis={true}>
          {spaceName}
        </Text>
      </KBSpace>
      {isHovering && showArrow && <ChevronRight size={16} />}
    </KBFlex>
  )
}

export default SpaceItem
