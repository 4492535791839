import { CLOUDFLARE_IMAGE_URL } from '@/utils/constants'
import { Image, ImageProps } from 'antd'

export interface KBImageProps extends ImageProps {
  /**
   * 是否使用图片src set自适应不同屏幕
   */
  usePreview?: boolean
  /**
   * 是否使用图片srcSet
   */
  useSrcSet?: boolean
  /**
   * 是否使用图片转换大小
   */
  useTransform?: boolean
}

const transformImage = (src: string, size: number) => {
  return `${CLOUDFLARE_IMAGE_URL}fit=contain,width=${size}/${src}`
}

const SRC_SET_SIZES = [320, 640, 960, 1280, 2560]
export function KBImage(props: KBImageProps) {
  const {
    src,
    width,
    useSrcSet = false,
    useTransform = true,
    ...restProps
  } = props

  const srcSetString = props.src?.startsWith('https://')
    ? SRC_SET_SIZES.map(
        (size) =>
          `${CLOUDFLARE_IMAGE_URL}fit=contain,width=${size}/${props.src} ${size}w`
      ).join(',')
    : ''

  const transformedSrc =
    width && props.src?.startsWith('https://')
      ? transformImage(props.src, Number(width) * 2)
      : props.src

  return (
    <Image
      preview={{ src }}
      {...restProps}
      src={useTransform ? transformedSrc : props.src}
      width={width}
      srcSet={useSrcSet ? srcSetString : undefined}
    ></Image>
  )
}

KBImage.PreviewGroup = Image.PreviewGroup
