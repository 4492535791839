interface ResourceTypeIconOptions {
  name: string
  icon: string
  iconWhite: string
  iconPrimary?: string
}

export const ResourceTypeIconArray: ResourceTypeIconOptions[] = [
  {
    name: 'resourceType1',
    icon: '/resourceType/resourceIcon1.svg',
    iconWhite: '/resourceType/resourceIconWhite1.svg',
    iconPrimary: '/resourceType/resourceIconPrimary1.svg',
  },
  {
    name: 'resourceType2',
    icon: '/resourceType/resourceIcon2.svg',
    iconWhite: '/resourceType/resourceIconWhite2.svg',
    iconPrimary: '/resourceType/resourceIconPrimary2.svg',
  },
  {
    name: 'resourceType3',
    icon: '/resourceType/resourceIcon3.svg',
    iconWhite: '/resourceType/resourceIconWhite3.svg',
    iconPrimary: '/resourceType/resourceIconPrimary3.svg',
  },
  {
    name: 'resourceType4',
    icon: '/resourceType/resourceIcon4.svg',
    iconWhite: '/resourceType/resourceIconWhite4.svg',
    iconPrimary: '/resourceType/resourceIconPrimary4.svg',
  },
  {
    name: 'resourceType5',
    icon: '/resourceType/resourceIcon5.svg',
    iconWhite: '/resourceType/resourceIconWhite5.svg',
    iconPrimary: '/resourceType/resourceIconPrimary5.svg',
  },
  {
    name: 'resourceType6',
    icon: '/resourceType/resourceIcon6.svg',
    iconWhite: '/resourceType/resourceIconWhite6.svg',
    iconPrimary: '/resourceType/resourceIconPrimary6.svg',
  },
  {
    name: 'resourceType7',
    icon: '/resourceType/resourceIcon7.svg',
    iconWhite: '/resourceType/resourceIconWhite7.svg',
    iconPrimary: '/resourceType/resourceIconPrimary7.svg',
  },
  {
    name: 'resourceType8',
    icon: '/resourceType/resourceIcon8.svg',
    iconWhite: '/resourceType/resourceIconWhite8.svg',
    iconPrimary: '/resourceType/resourceIconPrimary8.svg',
  },
  {
    name: 'resourceType9',
    icon: '/resourceType/resourceIcon9.svg',
    iconWhite: '/resourceType/resourceIconWhite9.svg',
    iconPrimary: '/resourceType/resourceIconPrimary9.svg',
  },
  {
    name: 'resourceType10',
    icon: '/resourceType/resourceIcon10.svg',
    iconWhite: '/resourceType/resourceIconWhite10.svg',
    iconPrimary: '/resourceType/resourceIconPrimary10.svg',
  },
  {
    name: 'resourceType11',
    icon: '/resourceType/resourceIcon11.svg',
    iconWhite: '/resourceType/resourceIconWhite11.svg',
    iconPrimary: '/resourceType/resourceIconPrimary11.svg',
  },
  {
    name: 'resourceType12',
    icon: '/resourceType/resourceIcon12.svg',
    iconWhite: '/resourceType/resourceIconWhite12.svg',
    iconPrimary: '/resourceType/resourceIconPrimary12.svg',
  },
  {
    name: 'resourceType13',
    icon: '/resourceType/resourceIcon13.svg',
    iconWhite: '/resourceType/resourceIconWhite13.svg',
    iconPrimary: '/resourceType/resourceIconPrimary13.svg',
  },
  {
    name: 'resourceType14',
    icon: '/resourceType/resourceIcon14.svg',
    iconWhite: '/resourceType/resourceIconWhite14.svg',
    iconPrimary: '/resourceType/resourceIconPrimary14.svg',
  },
  {
    name: 'resourceType15',
    icon: '/resourceType/resourceIcon15.svg',
    iconWhite: '/resourceType/resourceIconWhite15.svg',
    iconPrimary: '/resourceType/resourceIconPrimary15.svg',
  },
  {
    name: 'resourceType16',
    icon: '/resourceType/resourceIcon16.svg',
    iconWhite: '/resourceType/resourceIconWhite16.svg',
    iconPrimary: '/resourceType/resourceIconPrimary16.svg',
  },
  {
    name: 'resourceType17',
    icon: '/resourceType/resourceIcon17.svg',
    iconWhite: '/resourceType/resourceIconWhite17.svg',
    iconPrimary: '/resourceType/resourceIconPrimary17.svg',
  },
  {
    name: 'resourceType18',
    icon: '/resourceType/resourceIcon18.svg',
    iconWhite: '/resourceType/resourceIconWhite18.svg',
    iconPrimary: '/resourceType/resourceIconPrimary18.svg',
  },
  {
    name: 'resourceType19',
    icon: '/resourceType/resourceIcon19.svg',
    iconWhite: '/resourceType/resourceIconWhite19.svg',
    iconPrimary: '/resourceType/resourceIconPrimary19.svg',
  },
  {
    name: 'resourceType20',
    icon: '/resourceType/resourceIcon20.svg',
    iconWhite: '/resourceType/resourceIconWhite20.svg',
    iconPrimary: '/resourceType/resourceIconPrimary20.svg',
  },
  {
    name: 'resourceType21',
    icon: '/resourceType/resourceIcon21.svg',
    iconWhite: '/resourceType/resourceIconWhite21.svg',
    iconPrimary: '/resourceType/resourceIconPrimary21.svg',
  },
  {
    name: 'resourceType22',
    icon: '/resourceType/resourceIcon22.svg',
    iconWhite: '/resourceType/resourceIconWhite22.svg',
    iconPrimary: '/resourceType/resourceIconPrimary22.svg',
  },
]

/**
 * 获取资源类型图标，可选是否白色
 * @param name 图标名称
 * @param white 是否白色
 * @returns
 */
export const getResourceTypeIcon = (name?: string, white?: boolean) => {
  const icon =
    ResourceTypeIconArray.find((item) => item.name === name) ||
    ResourceTypeIconArray[0]
  return white ? icon.iconWhite : icon.icon
}

export const getResourceTypeIconPrimary = (name?: string) => {
  const icon =
    ResourceTypeIconArray.find((item) => item.name === name) ||
    ResourceTypeIconArray[0]
  return icon.iconPrimary
}
