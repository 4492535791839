import {
  KBFlex,
  KBForm,
  KBIcon,
  KBTooltip,
  KBTypography,
} from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { useTranslation } from 'react-i18next'

export interface ResourceSelectProps {
  selectedResourceList: KBResourceReservationFormProps['resourcesList']
  errorsMessages?: string
}

const ResourceSelect: React.FC<ResourceSelectProps> = (props) => {
  const { t } = useTranslation()
  const { selectedResourceList, errorsMessages } = props

  const resourcesName = useMemo(() => {
    return selectedResourceList.map((i) => i.name)?.join(', ')
  }, [selectedResourceList])
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <KBForm.Item
        label={
          <KBTooltip title={t('reservation.resource')}>
            <KBIcon size={24} name="presentation" />
          </KBTooltip>
        }
        validateStatus={errorsMessages ? 'error' : undefined}
        help={errorsMessages}
      >
        <KBFlex vertical gap={4}>
          <KBTypography.Paragraph
            className="tw-mb-0 tw-text-base tw-font-bold"
            ellipsis={{
              rows: 1,
              expanded,
              expandable: 'collapsible',
              onExpand: (_, info) => setExpanded(info.expanded),
              symbol: (
                <KBTypography.Link className="tw-text-base tw-font-medium">
                  {expanded
                    ? t('common.resourceClosed')
                    : t('common.resourceLength', {
                        length: selectedResourceList.length,
                      })}
                </KBTypography.Link>
              ),
            }}
          >
            {resourcesName}
          </KBTypography.Paragraph>
          {/* <KBText className=" tw-text-gray-500">
            {t('common.selectedResourceLength', {
              length: selectedResourceList.length,
            })}
          </KBText> */}
        </KBFlex>
      </KBForm.Item>
    </>
  )
}

export default ResourceSelect
