import { CustomFieldEntity, CustomFieldValue } from '@/api'
import {
  KBCheckbox,
  KBForm,
  KBInput,
  KBRadio,
  KBSelect,
} from '@/components/atoms'
import { isValidEmail } from '@/utils/string'
import { FormItemProps, InputNumber } from 'antd'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface KBCustomFieldFormItemProps extends FormItemProps {
  customField: CustomFieldEntity
  onChange?: (value: CustomFieldValue) => void
  parentName?: ''
  name?: string | string[]
  label?: string
  // 不直接用label是因为一些校验提示需要string类型，显示的label可能是一个ReactNode
  customLabel?: ReactNode
  disabled?: boolean
}

const KBCustomFieldFormItem: FC<KBCustomFieldFormItemProps> = (props) => {
  const { t } = useTranslation()
  const {
    customField,
    name = '',
    label,
    customLabel,
    disabled = false,
    onChange,
    ...restProps
  } = props

  const customFormItem = {
    label: label || `${customField.label}`,
    name: name || `__custom_${customField.id}`,
    type: customField.type,
    required: customField.required,
    id: customField.id,
  }
  return (
    <KBForm.Item
      {...restProps}
      label={customLabel || customFormItem.label}
      name={customFormItem.name}
      rules={[
        {
          required: customFormItem.required,
          message: t('common.enterCustomFormItem', {
            label: customFormItem.label,
          }),
        },
        {
          validator: async (_, value) => {
            if (!value) return Promise.resolve()
            if (customFormItem.type === 'Email') {
              // 可能为数组
              if (!isValidEmail(Array.isArray(value) ? value[0] : value)) {
                return Promise.reject(t('common.validEmail'))
              }
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      {(customFormItem.type === 'Text' ||
        customFormItem.type === 'Phone' ||
        customFormItem.type === 'Email' ||
        customFormItem.type === 'Address') && (
        <KBInput
          // placeholder={t('common.enterCustomFormItem', {
          //   label: customFormItem.label,
          // })}
          disabled={disabled}
          onChange={(e) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: [e.target.value],
            })
          }}
        />
      )}
      {customFormItem.type === 'Number' && (
        <InputNumber
          // placeholder={t('common.enterCustomFormItem', {
          //   label: customFormItem.label,
          // })}
          className="tw-w-full"
          disabled={disabled}
          onChange={(value) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: value ? [value.toString()] : undefined,
            })
          }}
        />
      )}
      {customFormItem.type === 'Select' && (
        <KBSelect
          // placeholder={t('common.selectCustomFormItem', {
          //   label: customFormItem.label,
          // })}
          disabled={disabled}
          onChange={(value) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: value ? [value] : undefined,
            })
          }}
        >
          {customField?.options?.map((option) => (
            <KBSelect.Option value={option.value} key={option.label}>
              {option.label}
            </KBSelect.Option>
          ))}
        </KBSelect>
      )}
      {customFormItem.type === 'MultiSelect' && (
        <KBSelect
          mode="multiple"
          // placeholder={t('common.selectCustomFormItem', {
          //   label: customFormItem.label,
          // })}
          disabled={disabled}
          onChange={(value) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: value,
            })
          }}
        >
          {customField?.options?.map((option) => (
            <KBSelect.Option value={option.value} key={option.label}>
              {option.label}
            </KBSelect.Option>
          ))}
        </KBSelect>
      )}
      {customFormItem.type === 'Textarea' && (
        <KBInput.TextArea
          // placeholder={t('common.enterCustomFormItem', {
          //   label: customFormItem.label,
          // })}
          disabled={disabled}
          autoSize={{ minRows: 3, maxRows: 6 }}
          onChange={(e) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: [e.target.value],
            })
          }}
        />
      )}
      {customFormItem.type === 'Checkbox' && (
        <KBCheckbox.Group
          value={customField?.options?.map((option) => option.value!)}
          disabled={disabled}
          onChange={(value) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: value,
            })
          }}
        >
          {customField?.options?.map((option) => (
            <KBCheckbox value={option.value} key={option.label}>
              {option.label}
            </KBCheckbox>
          ))}
        </KBCheckbox.Group>
      )}
      {customFormItem.type === 'Radio' && (
        <KBRadio.Group
          value={customField?.options?.map((option) => option.value)}
          disabled={disabled}
          onChange={(e) => {
            onChange?.({
              custom_field_id: customFormItem.id,
              label: customFormItem.label,
              value: e.target.value ? [e.target.value] : undefined,
            })
          }}
        >
          {customField?.options?.map((option) => (
            <KBRadio value={option.value} key={option.label}>
              {option.label}
            </KBRadio>
          ))}
        </KBRadio.Group>
      )}
    </KBForm.Item>
  )
}

export default KBCustomFieldFormItem
