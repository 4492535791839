import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomFieldFindMany } from '@/api'
import {
  KBDivider,
  KBForm,
  KBIcon,
  KBSkeleton,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import KBCustomFieldFormItem from '@/components/molecules/KBCustomFieldFormItem/KBCustomFieldFormItem'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { CustomFieldOwnerTypeEnum } from '@/enums/modelEnums'
import styles from './customFields.module.css'

interface CustomFieldsProps {
  resourcesList: KBResourceReservationFormProps['resourcesList']
}
/**
 * 自定义字段组件
 */
const CustomFields: React.FC<CustomFieldsProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList } = props
  const formRuleIds = resourcesList
    .filter((r) => r.form_rule_id || r.reservation_form_rule?.id)
    .map((r) => r.form_rule_id || r.reservation_form_rule?.id!)
  const { data: customFields, isPending } = useCustomFieldFindMany(
    {
      owner_type: CustomFieldOwnerTypeEnum.ReservationPolicyType,
      owner_ids: formRuleIds,
    },
    { query: { enabled: !!formRuleIds?.length } }
  )
  const resourcesWithCustomFields = useMemo(() => {
    return resourcesList
      .map((i) => {
        return {
          ...i,
          custom_fields: customFields?.filter(
            (customField) =>
              customField.owner_id === i.reservation_form_rule?.id ||
              customField.owner_id === i.form_rule_id
          ),
        }
      })
      .filter(Boolean)
  }, [resourcesList, customFields])

  const showCustomFields = useMemo(() => {
    return resourcesList.some((i) =>
      Boolean(
        customFields?.find(
          (customField) =>
            customField.owner_id === i.reservation_form_rule?.id ||
            customField.owner_id === i.form_rule_id
        )
      )
    )
  }, [resourcesList, customFields])

  return (
    <KBSkeleton
      loading={isPending && !!formRuleIds?.length}
      title={false}
      paragraph={{ rows: 3, width: '100%' }}
    >
      {showCustomFields ? (
        <>
          <KBDivider className=" tw-my-8" />
          <KBForm.Item
            label={
              <KBTooltip title={t('common.customFields')}>
                <KBIcon name="file-pen" size={24} />
              </KBTooltip>
            }
          >
            {resourcesWithCustomFields
              .filter((i) => Boolean(i.custom_fields?.length))
              .map((resource) => {
                return (
                  <div key={resource.id}>
                    {resource.custom_fields?.map((field) => {
                      return (
                        <KBCustomFieldFormItem
                          key={field.id}
                          wrapperCol={{ flex: '100%' }}
                          customField={field}
                          className={styles['reservation-form-form-item']}
                          customLabel={
                            <>
                              {field.label}
                              <KBText className="tw-text-gray-500">
                                ({resource.name})
                              </KBText>
                            </>
                          }
                          label={field.label}
                          name={`customFieldsValue.${resource.id}__customField__${field.id}`.split(
                            '.'
                          )}
                        />
                      )
                    })}
                  </div>
                )
              })}
          </KBForm.Item>
        </>
      ) : null}
    </KBSkeleton>
  )
}

export default React.memo(CustomFields)
