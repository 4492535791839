import { EmployeeWorkStatusEntity, useEmployeeWorkStatusFindMany } from '@/api'
import {
  KBAvatar,
  KBFlex,
  KBIcon,
  KBPopover,
  KBTypography,
} from '@/components/atoms'
import { KBUserAvatar } from '@/components/organisms'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { getUserAvatar } from '@/utils/avatarUrl'
import { isUserClient } from '@/utils/userEnvironment'
import { theme } from 'antd'
import dayjs from 'dayjs'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import WoxIconCompany from '~icons/wox-icons/company'
import WoxIconHome from '~icons/wox-icons/home'
import WoxIconRest from '~icons/wox-icons/rest'

export interface KBUserPopoverProps {
  user?: KBUserAvatar
  children: ReactNode | string
  toolTipContent?: React.ReactNode
}
export function KBUserPopover({
  user,
  children,
  toolTipContent,
}: KBUserPopoverProps) {
  const { t } = useTranslation()
  const { useToken } = theme
  const { token } = useToken()
  const navigate = useNavigate()
  const currentLocationId = useCurrentLocationId()
  const goToUserUrl = isUserClient()
    ? `/user/users/${user?.id}`
    : currentLocationId
      ? `/admin/locations/${currentLocationId}/users/${user?.id}`
      : `/admin/users/${user?.id}`

  const { data } = useEmployeeWorkStatusFindMany({
    date: dayjs().format('YYYY-MM-DD'),
    location_id: currentLocationId,
  })

  const workStatuses = data?.result as EmployeeWorkStatusEntity[]
  const workStatus = workStatuses?.find((status) => status.user_id === user?.id)
  let background = ''
  switch (workStatus?.status) {
    case 'WorkOffice':
      background = `linear-gradient(90deg, #6350F0 0%, #4DCEFE 100%)`
      break
    case 'WorkHome':
      background = `linear-gradient(90deg, #FF8008 0%, #FFC837 100%)`
      break
    case 'Rest':
      background = `linear-gradient(90deg, #02AAB0 0%, #00CDAC 100%)`
      break
    default:
      background = ``
  }
  const content = (
    <KBFlex vertical className="tw-min-w-[248px] tw-p-1">
      <KBFlex gap={8}>
        <KBAvatar
          size={40}
          src={getUserAvatar(user)}
          className="tw-flex-shrink-0 tw-cursor-pointer"
          onClick={(e) => {
            e?.stopPropagation()
            navigate(goToUserUrl)
          }}
        />
        <KBFlex vertical gap={8}>
          <KBFlex
            vertical
            gap={2}
            onClick={(e) => {
              e.stopPropagation()
              navigate(goToUserUrl)
            }}
            className="tw-cursor-pointer"
          >
            <KBTypography.Text className="tw-font-medium">
              {user?.name}
            </KBTypography.Text>
            <KBTypography.Text className="tw-text-xs tw-text-[var(--wox-color-text-description)]">
              {user?.email ? user?.email : t('common.noEmail')}
            </KBTypography.Text>
          </KBFlex>
          <KBFlex gap={6} align="center">
            {toolTipContent}
            {workStatus?.status && workStatus?.status !== 'Unknown' && (
              <KBFlex
                gap={4}
                align="center"
                className="tw-rounded-[44px] tw-px-2 tw-py-0.5"
                style={{
                  background: `${background}`,
                }}
              >
                {workStatus?.status === 'WorkOffice' && (
                  <WoxIconCompany className="tw-size-[14px]" />
                )}
                {workStatus?.status === 'WorkHome' && (
                  <WoxIconHome className="tw-size-[14px]" />
                )}
                {workStatus?.status === 'Rest' && (
                  <WoxIconRest className="tw-size-[14px]" />
                )}
                <KBTypography.Text className="tw-text-xs tw-text-white">
                  {workStatus?.status === 'WorkOffice' &&
                    workStatus?.location?.name}
                  {workStatus?.status === 'WorkHome' && 'Home'}
                  {workStatus?.status === 'Rest' && 'Rest'}
                </KBTypography.Text>
              </KBFlex>
            )}
            {user?.department?.name && (
              <KBFlex
                gap={4}
                align="center"
                className="tw-rounded-[44px] tw-bg-[var(--wox-color-fill-quaternary)] tw-px-2 tw-py-0.5"
              >
                <KBIcon name="network" size={12} />
                <KBTypography.Text
                  type="secondary"
                  className="tw-text-xs tw-font-normal"
                >
                  {user.department?.name}
                </KBTypography.Text>
              </KBFlex>
            )}
          </KBFlex>
        </KBFlex>
      </KBFlex>
    </KBFlex>
  )

  return (
    <KBPopover
      content={content}
      arrow={false}
      overlayInnerStyle={{
        border: `1px solid ${token.colorBorderSecondary}`,
      }}
      align={{
        offset: [0, -12],
      }}
    >
      {children}
    </KBPopover>
  )
}
