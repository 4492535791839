import { lazyLoad } from '@/utils/lazyLoad'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

// Lazy load all components
const BlankPage = lazy(() => import('@/pages/user/blank/Blank'))
const DashboardPage = lazy(() => import('@/pages/user/dashboard'))
const DeliveryInfo = lazy(() => import('@/pages/user/deliveries/DeliveryInfo'))
const DeliveryPage = lazy(() => import('@/pages/user/deliveries/DeliveryPage'))
const ReservationsPage = lazy(
  () => import('@/pages/user/resources/ReservationsPage')
)
const UserReservationsWaitListInfo = lazy(
  () => import('@/pages/user/resources/ReservationsWaitListInfo')
)
const UserReservationDetailsPage = lazy(
  () => import('@/pages/user/resources/UserReservationDetailsPage')
)
const TaskInfo = lazy(() => import('@/pages/user/tasks/TaskInfo'))
const Service = lazy(() => import('@/pages/user/tasks/TaskPage'))
const UsersPage = lazy(() => import('@/pages/user/users'))
const UserInfoPage = lazy(() => import('@/pages/user/users/UserInfo'))
const PersonalSettings = lazy(() => import('@/pages/user/userSettings/index'))
const VisitorInfo = lazy(
  () => import('@/pages/user/visitors/components/VisitorInfo')
)
const VisitorPage = lazy(() => import('@/pages/user/visitors/VisitorPage'))
const WorkStatusCalendarPage = lazy(
  () => import('@/pages/user/workStatus/WorkStatusCalendar')
)

export const userRoutes: RouteObject[] = [
  // { path: '', element: <Navigate to="/user/dashboard" replace /> },
  { path: '' },
  { path: 'dashboard', element: lazyLoad(DashboardPage) },
  { path: 'hybrid-work', element: lazyLoad(WorkStatusCalendarPage) },
  { path: 'users', element: lazyLoad(UsersPage) },
  { path: 'users/settings', element: lazyLoad(PersonalSettings) },
  { path: 'users/settings/information', element: lazyLoad(PersonalSettings) },
  { path: 'users/settings/count', element: lazyLoad(PersonalSettings) },
  { path: 'users/settings/count/general', element: lazyLoad(PersonalSettings) },
  {
    path: 'users/settings/count/notifications',
    element: lazyLoad(PersonalSettings),
  },
  {
    path: 'users/settings/count/password',
    element: lazyLoad(PersonalSettings),
  },
  { path: 'users/:id', element: lazyLoad(UserInfoPage) },
  { path: 'tasks', element: lazyLoad(Service) },
  { path: 'tasks/all', element: lazyLoad(Service) },
  { path: 'tasks/pending', element: lazyLoad(Service) },
  { path: 'tasks/received', element: lazyLoad(Service) },
  { path: 'tasks/completed', element: lazyLoad(Service) },
  { path: 'tasks/rejected', element: lazyLoad(Service) },
  { path: 'tasks/:id', element: lazyLoad(TaskInfo) },
  { path: 'reservations', element: lazyLoad(ReservationsPage) },
  { path: 'reservations/types/:id', element: lazyLoad(ReservationsPage) },
  { path: 'reservations/records', element: lazyLoad(ReservationsPage) },
  { path: 'reservations/waitLists', element: lazyLoad(ReservationsPage) },
  {
    path: 'reservations/waitLists/:id',
    element: lazyLoad(UserReservationsWaitListInfo),
  },
  { path: 'reservations/:id', element: lazyLoad(UserReservationDetailsPage) },
  {
    path: 'reservations/share/:id',
    element: lazyLoad(UserReservationDetailsPage),
  },
  { path: 'deliveries', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/all', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/pending', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/pickedUp', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/rejected', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/expired', element: lazyLoad(DeliveryPage) },
  { path: 'deliveries/:id', element: lazyLoad(DeliveryInfo) },
  { path: 'visitors', element: lazyLoad(VisitorPage) },
  { path: 'visitors/:id', element: lazyLoad(VisitorInfo) },
  //  空白页面，目前仅用于占位，后续应删除
  { path: 'blank', element: lazyLoad(BlankPage) },
]
