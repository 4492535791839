import { Checkbox, Table } from 'antd'
import { RowSelectMethod } from 'antd/es/table/interface'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Pagination } from '@/api'
// import spinLoadingIcon from '@/assets/icons/loading.svg?url'
import { KBButton } from '@/components/atoms/KBButton'
import { KBFlex } from '@/components/atoms/KBFlex'
import {
  KBSkeletonTable,
  KBSkeletonTableProps,
} from '@/components/atoms/KBSkeletonTable'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBSpin } from '@/components/atoms/KBSpin'
import { KBText } from '@/components/atoms/KBText'
import { KBEmpty, KBEmptySize } from '@/components/organisms/KBEmpty'
// console.log(111, <KBSvgIcon src={spinLoadingIcon} />)
export enum LoadingType {
  Skeleton = 'skeleton',
  Spin = 'spin',
  Progress = 'progress',
}
// eslint-disable-next-line typescriptEslint/no-explicit-any
export interface KBTableProps<T = any> extends KBSkeletonTableProps<T> {
  loadingType?: LoadingType
  queryComponent?: ReactNode //查询表单
  meta?: Pagination // pagination
  enableRowSelection?: boolean //是否禁用批量选择
  batchOps?: (
    currentSelectedRowKeys: React.Key[],
    isSelectAll?: boolean
  ) => ReactNode //操作的Buttons
  showSelectAll?: boolean
  showSelectBar?: boolean
  emptySize?: KBEmptySize
}

export function KBTable(props: KBTableProps) {
  const { t } = useTranslation()
  const {
    queryComponent,
    loadingType,
    rowKey = 'id',
    rowSelection,
    enableRowSelection,
    meta,
    dataSource,
    loading,
    showSelectAll = true,
    showSelectBar = true,
    batchOps,
    emptySize,
    columns,
    ...tableProps
  } = props
  const myLoadingType = loadingType || LoadingType.Spin

  const [currentSelectedRowKeys, setCurrentSelectedKeys] = useState<
    React.Key[]
  >([])
  // 判断是否有操作按钮
  const hasOperate = columns?.findIndex((item) => item.key === 'operate') !== -1
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const isHasSelectedRowKeys = useMemo(
    () => currentSelectedRowKeys.length > 0,
    [currentSelectedRowKeys]
  )

  function onRowSelectionChange(
    selectedRowKeys: React.Key[],
    selectedRows: typeof dataSource,
    info: { type: RowSelectMethod }
  ) {
    if (isSelectAll && selectedRowKeys.length !== meta?.count) {
      setIsSelectAll(false)
    } else if (!isSelectAll && selectedRowKeys.length === meta?.count) {
      setIsSelectAll(true)
    }
    setCurrentSelectedKeys(selectedRowKeys)
    rowSelection?.onChange &&
      rowSelection?.onChange(selectedRowKeys, selectedRows, info)
  }
  useEffect(() => {
    if (rowSelection?.defaultSelectedRowKeys) {
      setCurrentSelectedKeys(rowSelection.defaultSelectedRowKeys || [])
    }
    if (rowSelection?.selectedRowKeys) {
      setCurrentSelectedKeys(rowSelection.selectedRowKeys || [])
      if (rowSelection?.selectedRowKeys?.length === 0) {
        setIsSelectAll(false)
      }
    }
  }, [rowSelection])

  useEffect(() => {
    if (isSelectAll) {
      // 选择全部
      setCurrentSelectedKeys(
        (dataSource || []).map((data) => data[rowKey as string])
      )
    }
  }, [isSelectAll, dataSource])

  return (
    <div>
      {isHasSelectedRowKeys && showSelectBar ? (
        <KBFlex justify="space-between" className="tw-mb-5" align="center">
          <KBSpace size={'middle'} className=" tw-ml-2 tw-h-[36px] ">
            <Checkbox
              indeterminate={isHasSelectedRowKeys && !isSelectAll}
              checked={isSelectAll}
              onChange={(e) => {
                const checked = e.target.checked
                setIsSelectAll(checked)
                if (checked) {
                  // 取消选择全部
                  setCurrentSelectedKeys([])
                }
              }}
            />

            <KBText>
              {t('table.selectedItems', {
                length: isSelectAll
                  ? meta?.count
                  : currentSelectedRowKeys.length,
              })}
            </KBText>
            {/* {meta && meta.count > meta.limit && ( */}
            {showSelectAll && (
              <KBButton
                type="text"
                className="tw-text-[var(--wox-color-primary)]"
                onClick={() => {
                  setIsSelectAll(!isSelectAll)
                  if (isSelectAll) {
                    // 取消选择全部
                    setCurrentSelectedKeys([])
                  }
                }}
              >
                {isSelectAll
                  ? t('common.cancelSelectAll')
                  : t('common.selectAllItems', { count: meta?.count })}
              </KBButton>
            )}
            {/* )} */}

            {batchOps && batchOps(currentSelectedRowKeys, isSelectAll)}
          </KBSpace>
          <KBButton
            type="link"
            onClick={() => {
              setCurrentSelectedKeys([])
              // 处理员工管理取消后，切换tab后仍处于选择状态
              rowSelection?.onChange &&
                rowSelection?.onChange([], [], {
                  type: 'none',
                })
            }}
          >
            {t('common.cancel')}
          </KBButton>
        </KBFlex>
      ) : (
        queryComponent
      )}
      {myLoadingType === LoadingType.Skeleton ? (
        <KBSkeletonTable
          rowSelection={
            !enableRowSelection
              ? undefined
              : {
                  ...rowSelection,
                  selectedRowKeys: currentSelectedRowKeys,
                  onChange: onRowSelectionChange,
                }
          }
          dataSource={dataSource}
          locale={{
            emptyText: !loading ? <KBEmpty size={emptySize} /> : <KBSpin />,
          }}
          {...({ rowKey, ...tableProps } as Omit<
            KBSkeletonTableProps,
            'dataSource'
          >)}
          loading={!!loading}
          pagination={false}
        />
      ) : (
        <Table
          columns={
            hasOperate
              ? columns?.map((item) => {
                  if (item.key === 'operate') {
                    return {
                      ...item,
                      fixed: 'right',
                    }
                  }
                  return item
                })
              : columns
          }
          style={{ tableLayout: 'fixed' }}
          scroll={{ x: 'max-content' }}
          rowSelection={
            !enableRowSelection
              ? undefined
              : {
                  ...rowSelection,
                  selectedRowKeys: currentSelectedRowKeys,
                  onChange: onRowSelectionChange,
                }
          }
          dataSource={dataSource}
          locale={{
            emptyText: !loading ? (
              <KBEmpty size={emptySize} />
            ) : (
              <div className="tw-h-[250px]"></div>
            ),
          }}
          {...{ rowKey, ...tableProps }}
          loading={{
            spinning: !!loading,
            indicator: (
              <KBFlex justify="center" align="center">
                <KBSpin />
              </KBFlex>
            ),
          }}
          pagination={false}
        ></Table>
      )}
    </div>
  )
}
