import { ReservationShowEntity } from '@/api'
import {
  KBCheckbox,
  KBFlex,
  KBForm,
  KBIcon,
  KBSpace,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import {
  FormValueProps,
  KBResourceReservationFormProps,
} from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { KBUserSelect } from '@/components/organisms'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * 选择主持人
 */
interface HostSelectProps {
  id?: string
  resourcesList: KBResourceReservationFormProps['resourcesList']
  reservationInfo?: ReservationShowEntity
  value?: {
    hostId?: number
    isPrivate?: boolean
  }
  errorsMessages?: string
  onChange?: (value: { hostId?: number; isPrivate?: boolean }) => void
}

const HostAndPrivate: React.FC<HostSelectProps> = (props) => {
  const { t } = useTranslation()
  const { value, onChange, resourcesList = [], reservationInfo, id } = props

  const showPrivate = resourcesList.some(
    (i) => i.reservation_reserve_rule?.rules?.allow_private
  )

  return (
    <>
      <KBFlex id={id} align="start" gap={6}>
        <KBFlex vertical gap={8} className="tw-w-full">
          <KBUserSelect
            placeholder={t('reservation.hostPlaceholder')}
            className="tw-w-full"
            value={value?.hostId}
            onChange={(userId: number) => {
              onChange?.({
                ...value,
                hostId: userId,
              })
            }}
          />

          {showPrivate && (
            <KBFlex align="center" gap={6}>
              <KBCheckbox
                checked={value?.isPrivate}
                onChange={(e) => {
                  onChange?.({
                    ...value,
                    isPrivate: e.target.checked,
                  })
                }}
              />

              <KBTooltip title={t('reservation.privateTooltip')}>
                <KBSpace size={4} align="center">
                  <KBText>{t('reservation.privateText')}</KBText>
                  <KBIcon name="info" className=" tw-block" />
                </KBSpace>
              </KBTooltip>
            </KBFlex>
          )}
        </KBFlex>
      </KBFlex>
    </>
  )
}

/**
 * 选择预订人以及是否私密预定
 */
const HostSelect: React.FC<HostSelectProps> = (props) => {
  const { t } = useTranslation()
  const {
    resourcesList,
    reservationInfo,
    value,
    errorsMessages,
    ...restProps
  } = props
  const [validateMessage, setValidateMessage] = useState('')
  /**
   * 主持人和私密预定校验
   */
  const validateHost = (host: FormValueProps['host']) => {
    if (!host?.hostId) {
      setValidateMessage(t('reservation.requiredHost'))
    } else {
      setValidateMessage('')
    }
    return Promise.resolve()
  }

  const errors = useMemo(() => {
    if (validateMessage) {
      return validateMessage
    }
    return errorsMessages
  }, [validateMessage, errorsMessages])

  return (
    <>
      <KBForm.Item
        name="host"
        label={
          <KBTooltip title={t('common.host')}>
            <KBIcon name="circle-user-round" size={24} />
          </KBTooltip>
        }
        rules={[
          {
            validator: (_, host) => validateHost(host),
          },
        ]}
        validateStatus={errors ? 'error' : undefined}
        help={errors}
      >
        <HostAndPrivate
          resourcesList={resourcesList}
          reservationInfo={reservationInfo}
          {...restProps}
        />
      </KBForm.Item>
      {/* 为了解决表单报错时，与预定表单中分割线margin塌陷问题 */}
      {/* {errors && <div className=" tw-overflow-hidden" />} */}
    </>
  )
}

export default React.memo(HostSelect)
